import React, {ReactNode} from "react"
import { graphql, Link, navigate } from "gatsby"
import Button from "../components/Button/Button";
import Layout from "../components/Layout/Layout";
import SEO from "../components/SEO/SEO";


const IndexPage = ({ data }: any) => {

  console.log(data)

  const allBlogPosts: any[] = [...data.allStrapiArticles.edges]

  const featuredBlogPost = {
    ...allBlogPosts[0].node
  }

  const upper = (lower: string) => lower.replace(/^\w/, c => c.toUpperCase())

  return (
      <div>
        <Layout style={{margin: 0}}>
          <SEO url={"https://blog.retrobie.com"}
               title={"Retrobie | Home of Streetwear and UI Design"}
               image={`https://cms.retrobie.com${featuredBlogPost.image.formats.large?.url}`}
               description={"A blog about of streetwear and ui design & interaction"}
          />
          <div className="blog-template--hero">
            <div className="hero--imag">
              <img
                  style={{borderRadius: "6px"}}
                  src={"https://cms.retrobie.com" + featuredBlogPost.image.formats.large.url}
                  alt={"image"}/>
            </div>
            <div className="featured-image--parent">
              <div>
                <small>
                  FEATURED
                </small>
                <h1>{featuredBlogPost.title}</h1>
                <p style={{color: "#222"}}>
                  {featuredBlogPost.snippet}
                </p>

                <Link to={`/${featuredBlogPost.slug}`}>
                  <Button
                      isColor={"ghost"}
                  >
                    Read more
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div style={{background: "#f6f6f6", padding: "48px 48px"}}>
            <h2 style={{color: "#353535"}}>Older Posts</h2>
            <div className="blog-list--parent">
              {
                allBlogPosts.map(({node}) => (
                    <Link to={node.slug}>
                      <div className="blog-list--item" key={node.id}
                           onClick={() => navigate(`${node.slug}`)}>

                        <div className="blog-list--image">
                          <img
                              src={"https://cms.retrobie.com" + node.image.formats.thumbnail?.url}
                              alt={"image"}/>
                        </div>
                        <div className="blog-list--description">
                          <h4>{node.title}</h4>
                        </div>
                        <div className="blog-list--description">
                          <p style={{margin: 0, fontSize: "12px"}}>
                            Updated {node.published_at}
                          </p>
                        </div>
                      </div>
                    </Link>
                ))
              }
            </div>
          </div>
        </Layout>
      </div>
  );
};

export default IndexPage

export const query = graphql`
query IndexPageQuery {
    allStrapiArticles(sort: {fields: updated_at, order: DESC}) {
    edges {
      node {
        title
        description
        image {
          formats {
            thumbnail {
              url
            }
            large {
              url
            }
          }
        }
        snippet
        slug
        published_at(fromNow: true)
      }
    }
  }
}

`;
